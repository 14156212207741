jQuery(document).ready(function($) {

//STELLAR

// $(window).stellar();

//INIT WOW

var wow = new WOW({
    boxClass: "wow",
    animateClass: "is-animating",
    offset: 0,
    mobile: !0,
    live: !0
});

wow.init();



}); //END DOCREADY


//PRELOADER

jQuery(window).on('load', function() { // makes sure the whole site is loaded 
  jQuery('#status').fadeOut(); // will first fade out the loading animation 
  jQuery('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website. 
  jQuery('body').delay(150).css({'overflow':'visible'});
})




// function fixHead() {

//       var viewport;
//       var hero;
//       var header;
//       var newheight;
//       var titlebar;

//       // if(Foundation.MediaQuery.atLeast('medium')){
        
//           //console.log('at least medium!');
//           viewport = jQuery(window).height();
//           hero = jQuery('#hero').outerHeight();
//           header = jQuery('.title-bar-wrapper').outerHeight();
          
//           newheight = (viewport-header);   
//           jQuery('#hero').css("height",newheight);
//          // console.log('header '+header);
//           //console.log('viewport '+viewport)
//           //console.log('altezza'+newheight);
//        //  }
      
//        //  else {
//        //    action = jQuery('.in-slide-content').outerHeight();
//        //    titlebar = jQuery('.title-bar').outerHeight();
//        //     jQuery('#home-banner').css("height",action);
//        //     jQuery('#home-banner').css("top",titlebar);
//        //   // console.log('not medium');
//        // }

//     };

    

//       jQuery(document).ready(fixHead);
//         jQuery(window).resize(fixHead);

